import React, { useEffect, useState } from 'react';
import { WindowAlert } from 'packard-belle';
import { help24 } from '../../icons/index';

function ConnectWallet(props) {
  const [message, setMessage] = useState('Connecting wallet...');
  useEffect(() => {
    connectMetamask();
  }, []);

  const connectMetamask = () => {
    if (typeof window.ethereum !== 'undefined') {
      return window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then((accounts) => {
          //setAccounts(accounts) <------ Todo need a context for the metamask
          console.log('accounts', accounts);
          setMessage('Wallet Connected!');
        })
        .catch((err) => setMessage(err.message));
    }
    setMessage(
      "Sorry seems that your browser doesn't support this! Please install a wallet extension such as Metamask!"
    );
  };

  return (
    <WindowAlert
      icon={help24}
      onOK={() => props.onClose(props.program)}
      onCancel={() => props.onClose(props.program)}
      title={'Connect Wallet!'}
    >
      {message}
    </WindowAlert>
  );
}

export default ConnectWallet;
