import * as icons from '../icons';
import clownAscii from './textFiles/clownAscii';
import readme from './textFiles/readme';

const desktop = [
  {
    title: 'My Social Computer',
    icon: icons.myComputer,
    component: 'ExplorerWindow',
    data: {
      content: [
        {
          title: '(C:) Join Discord!',
          icon: 'discord',
          url: 'https://discord.gg/EMbceegUCP',
        },
        {
          title: '(D:) Twitter!',
          icon: 'twitter',
          url: 'https://twitter.com/Odd_ClownsNFT',
        },
        {
          title: '3 1/2 Floppy (A:)',
          icon: 'floppy32',
          failState: {
            message:
              "Did everyone else's computer take ages to load the 'no floppy disc inserted' message or was that just mine?",
            loadTime: 8000,
          },
        },
      ],
    },
  },
  {
    title: 'Secrets of the clowns.',
    icon: icons.home,
    component: 'StoryView',
  },
  {
    title: 'Mint Clowns!!!',
    icon: icons.digiCamAxel4,
    component: 'InternetExplorer',
    data: {
      __html: readme,
    },
  },
  {
    title: 'Clown Generator',
    icon: icons.digiCam,
    component: 'ClownSeption',
  },
  {
    title: 'Connect Wallet',
    icon: icons.bsertUnlocker,
    component: 'ConnectWallet',
  },
  {
    title: 'Net Searcher',
    icon: icons.netSercher,
    component: 'Notepad',
    data: {
      content: clownAscii,
      readOnly: true,
    },
  },
];

export default desktop;
