import * as icons from '../icons';

const programs = [
  {
    title: 'Notepad',
    icon: icons.notepad16,
    component: 'Notepad',
    multiInstance: true,
  },
  {
    title: 'Paint',
    icon: icons.paint16,
    component: 'IframeWindow',
    data: { src: 'https://jspaint.app/', creator: 'https://github.com/1j01' },
    multiInstance: true,
  },
];

const favorites = [
  {
    title: 'kiter.eth',
    type: 'ExternalLink',
    icon: icons.htmlFile16,
    href: 'https://twitter.com/kiter_js',
  },
];

export const find = [
  { title: 'Files or Folders...', icon: icons.findFiles16, isDisabled: true },
  {
    title: 'Computer...',
    icon: icons.findComputer16,
    isDisabled: true,
  },
  {
    title: 'On the Internet...',
    icon: icons.findOnline16,
    type: 'ExternalLink',
    href: 'https://google.com',
  },
];

export default [
  {
    title: 'Team',
    icon: icons.folderFavorites24,
    options: favorites,
  },
  {
    title: 'Programs',
    icon: icons.folderProgram24,
    options: programs,
  },
];
