export default `
%#,,/&&&%/,,&&&%#,,(&&%%*,,&&&%(,,#&&%%,,/&&&%/,,%&&%#,,(&&%%*,,&&&%(,,#&&%%*,*&&&%(,,%&&%%,,/&&&%/,
  ,*%#//(*,(%#*%/,*%#/((*,#%(*#/,/%###%%#((#(///##(///####%%%(,#%#,%/,/%#/((*,#%(*#/,/%#*#(,,%%(/#*,(%
  #*%&(,*(/(&#*,((*%&/,/#*#&#,*(%#((####(//(#(((###((##///(##((#%&/*((/%%/,/#*#&#,*((/%%*,/#,#&(,*(/(&
  %#*,/&@&%/,,&@&%(*,(&@%%**&&%%%(///(#((((           .(((##(///#%&&&%#*,(&@%#*,*&@&%(*,#&@%#*,/&@&%/,
  ,*%%(/#*,#%#*%/,/%#/(&&&&&#/////.....                    .....(////#&&&%%(*,%%(*#*,(%#*#/,*%%(/#*,#%
  #*#&(,/(/(&#*,((*%%%&#////####(,                              *#((##(//(%&#*(&(,*(//%%*,(#,#&(,/(//&
  %#*,/&@&#/*,%@&%((&%%#####(                                       .((/(#%%%&/,*&&&%(*,#&@%#*,/&@&#/*
  ,*&%(/#*,#%#*&**&%(///(.                                              *(///#&&#*%*,(%#*%/,*&%(/#*,#%
  (*#&/,/(*/&#,,#&&%#(((/.           ,****            ****,             ./((#%&&&,*#//%#*,((,(&/,/(*/&
  %(*,/&@&#/*,%&&(///(*          .,.      ,          ,      .,.            /(//(#&&&%(*,#&@%#*,/%@&#/*
  ,*&%((#,,%%%%&&#####*                   .          .                     /#//##&&#,(%%/%*,*&%(/#,,#%
  /*(&/,/(*/%&##///*           .(##/,,,.                .(##/,,,.             (///(#&#%#*,#(,(&/,/(*/%
  %(/,/%@&#/(%%###(*         *#####/,,,,,,.           *#####/,,,,,,.          /#/(#%%(*,(&&%(/,*%@&#/*
  ,*&%(((,*%&&#/////        #######/,,,,,,,.         #######/,,,,,,,.         (#//##&&&%/%*,*&%#(#,,%%
  /,(%*,/(**%&##((#/       /(((((((*,,,,,,,,.       /(((((((*,,,,,,,,.        ((//(#&#%#*,#/,/&/,/(**%
  %(/,*%@&#/(%%(///,       ////////*,,,,,,,,......  ////////*,,,,,,,,.        *#((#%%#*,(&@&(/,*%@&#/*
  ,/&%#((,*%&&##//#/       *******....,,,,,,........*******....,,,,,,.        (////#&&&%/%*,*&%#((,*%%
  *,/%*,((**%/%%#//*       ///////,..,,,,,,,........///////*..,,,,,,,.        (###%#**#(*,%/,/&*,/(**%
  &(/,*%@&#(*%&#(//(/      ////////*,,,,,,,,........////////*,,,,,,,,.       *///##&#(*,(&@&(/,*#@&#(*
  ,/&%#((,*%%%&&###/..    ..///////*,,,,,,,,........ ///////*,,,,,,,..      ..####%(*#&%(%*,/&%#((,*%%
  *,/%*,((**%/,&&(///(*   ...//////*,,,,,(,,...,,/.  .//////*,,,,,,,..     *///##&&#**#(*,%*,/%*,((**%
  &/(,*#@&((*,(&&%#((/.   ...*(***,,,,,,,#/,,,,,/(,   *(****,,,,,,....     ,/(##%&&&#(/,/&@&/(,*#@&#(*
  ,(&%#((,*%&%/%,*&&&&&*   ...,%(,,,,,(#(.//###(/,     .#/,,,,,/#(..      (&#&&&%(#,*#&%(#*,/&%#((,*%&
  ,,*%,,((,,%*,,&,,/#,,#&,    .,#(/*,*(*            ,,  .((*,*/(.       /&/%/,,&,,/%,,#(,,&*,*%,,((,,%
  &/(,*#@&((*,/@&%//,*%@&(%(    .*/*,*.   ........       .,*,*/.     ,##**%@&/(,*(&&#(/,/&&&/(,*#@&#(*
  ,(&%#(/,/%&%(#,*#&%#(/,/&%%%*,  ,*,.                     .,,.   */%(#&%##*,/&%%((,*%&%(#*,(&%#((,/%&
  *,*%**((,,%*,*&*,/#**#/,,&*,/&&&/..                        ..#&&,*&*,(#**%/,,&*,/%*,#(,*&*,*%**((*,%
  &/(,*#@&((*,/@&%/(,*#@&/(*,/@&#/(/#&&##,,            *##%&&&((*,/@&%//,*%@&/#,,(@&#(/,*&@&*(,*(@&((*
  ,(&%#(/,/&&%(#,*#&%#(/,(&&%((,*%&%#(*%&***,,,,,,,,,***&%#(/,/&&%((,*#&%#(*,(&&%((,/%&%##*,(&%#(/,/%&
  *,*#**#(*,#/,*&*,/#**%(*,%*,/%*,((**%&&..............*&#**#(*,%*,/%*,/(**%/,,%**(%*,((**&*,*%**(#*,#
  &*#,*(@&((*,*@@%/(,*#@&/#%%&&&&&&%#####.            ..#####%&&&&&&&&%(,*%@&/#,,/&&#(/,*%@&*#,*(@&((*
  ,#&%#(/,/&&%#(,*#&%#%#%%###############*             .###############%%#(*,(&&%#/,/%&%#(*,#&&%#/,/&&
  *,*#**##*,#/,/&*,/&%*/####################(,.    ,(#################/.../%(,,%**(%*,((*/&/,*#**##*,#
  &*#,,(@&((*,*@&&&#..../###########################################(........%%,,/@&#(/,*%@&*#,,(@&((*
  ,%&&%#/,(&&%#(#%*......,(########################################*..........*&%#/,/%&%#(*,#&&%#/,(&&
  /,*#**##*,#/,/&%.........#######################################...........,*&%*(%*,((*/%/,*#**##*,(
  &*#,,(&&((*,,&&##/........####################################,......*########&&&&#//,,%&&,#,,/&&((/
`;
