import React, { useEffect, useState } from 'react';
import { WindowProgram } from 'packard-belle';

import Window from '../tools/Window';

const CLOWN_AMOUNT = 99;

const randomizeLaunchSpot = (max) => Math.ceil(Math.random() * max);

const generateClownWindow = () => {
  return {
    x: randomizeLaunchSpot(window.innerWidth),
    y: randomizeLaunchSpot(window.innerHeight),
  };
};

function ClownSeption(props) {
  const [clownWindows, setClownWindows] = useState([generateClownWindow()]);

  useEffect(() => {
    const generateNewWindow = setInterval(() => {
      setClownWindows((state) => {
        if (state.length >= CLOWN_AMOUNT) {
          clearInterval(generateNewWindow);
        }

        return [...state, generateClownWindow()];
      });
    }, 50);
  }, []);

  return (
    <>
      {clownWindows.map(({ x, y }, index) => (
        <Window
          {...props}
          Component={WindowProgram}
          title={'The secrets of the clowns'}
          minHeight={319}
          resizable={false}
          minWidth={300}
          initialY={y}
          initialX={x}
        >
          <img
            src={`clowns/${CLOWN_AMOUNT - ((index + 1) % CLOWN_AMOUNT)}.png`}
            alt='Clown'
          />
        </Window>
      ))}
    </>
  );
}

export default ClownSeption;
