import React, { useState } from 'react';
import { Button, Message } from 'retro-ui';
import styled, { keyframes } from 'styled-components';
import { WindowAlert } from 'packard-belle';
import { help24 } from '../icons/index';
import mint from '../mintNFT'
import theme from '../theme';

const TITLE_TRAIL_AMOUNT = 5; //max 5

const IntroScreen = () => {
  const [message, setMessage] = useState(null);
  return (
    <MainContainer>
      <Background />
      <NavBar />
      <Clown1 src='clowns/8.png' alt='clown1' />
      <Clown2 src='clowns/1.png' alt='clown2' />
      <Gradient />
      <InfoContainer>
        <TextContainer>
          {[...Array(TITLE_TRAIL_AMOUNT).fill('ODD CLOWNS')].map(
            (message, index) => (
              <CustomMessage
                style={{
                  opacity: [1, 0.8, 0.6, 0.4, 0.2][index],
                  color: Object.values(theme.color).reverse()[
                    index % Object.values(theme.color).length
                  ],
                  zIndex: TITLE_TRAIL_AMOUNT - index,
                }}
                offset={index}
              >
                {message}
              </CustomMessage>
            )
          )}
        </TextContainer>
        <MintBox>
          {[...Array(TITLE_TRAIL_AMOUNT).fill('Coming Soon!')].map(
            (message, index) => (
              <ComingSoonMessage
                style={{
                  color: Object.values(theme.color).reverse()[
                    index % Object.values(theme.color).length
                  ],
                  zIndex: TITLE_TRAIL_AMOUNT - index,
                }}
                offset={index}
              >
                {message}
              </ComingSoonMessage>
            )
          )}
          <StyledButton
            onClick={mint}
          >
            Mint!
          </StyledButton>
        </MintBox>
      </InfoContainer>
      {message && (
        <WindowAlert
          icon={help24}
          onOK={() => setMessage(null)}
          onCancel={() => setMessage(null)}
          title={'Mint!'}
        >
          Coming Soon!
        </WindowAlert>
      )}
    </MainContainer>
  );
};

const StyledButton = styled(Button)`
  z-index: 1000;
  margin-top: 100px;

  @media (max-width: 600px) {
    margin-top: 0px;
  }
`;

const MainContainer = styled.div`
  display: flex;
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABFklEQVRYR9WXURLDIAhE6/0PbSdOtUpcd1Gnpv1KGpTHBpCE1/cXq+vrMph7dGvXZTtpfW10DCA5jrH1H0Jhs5E0hnZdCR+vb5S8Nn8mQCeS9BdSalYJqMBjAGzq59xAESN7VFVUgV8AZB/dZBR7QTFDCqGquvUBVVoEtgIwpQRzmANSFHgWQKExHdIrPeuMvQNDarXe6nC/AutgV3JW+6bgqQLeV8FekRtgV+ToDKEKnACYKsfZjjkam7a0ZpYTytwmgainpC3HvwBocgKOxqRjehoR9DFKNFYtOwCGYCszobeCbl26N6yyQ6g8X/Wex/rBPsNEV6qAMaJPMynIHQCoSqS9JSMmwef51LflTgCRszU7DvAGiV6mHWfsaVUAAAAASUVORK5CYII=),
    auto;
  flex-direction: column;
  height: 100%;
  width: 100%;
  @media (max-width: 600px) {
    height: 75%;
  }
`;

const Background = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: ${(props) => props.theme.color.yellow};
  background-size: 20%;
  background-image: url('background.gif');
  background-repeat: repeat;
`;

const InfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const Gradient = styled.div`
  display: flex;
  flex: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: ${(props) =>
    `linear-gradient(${props.theme.color.blue},rgba(0, 0, 0, 0), ${props.theme.color.yellow} )}`};
`;

const MintBox = styled.div`
  display: flex;
  flex: 0.8;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;

  @media (max-width: 750px) {
    justify-content: space-around;
    font-size: 6vw;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const NavBar = styled.nav`
  display: flex;
  height: 52px;
  opacity: 1;
  justify-content: flex-end;
`;

const NavText = styled(Message)`
  color: ${(props) => props.theme.color.white};
  z-index: 10;
  font-size: 0.8vw;
  margin-right: 20px;
`;

const floatAnimation = keyframes`
 0% { transform:translateY(${0}%)}
 50% { transform:translateY(${500}%)}
 100% { transform:translateY(${0}%)}
`;

const breathing = keyframes`
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.8);
  }
`;

const ClownFloatAnimation = keyframes`
 0% { transform:translateY(${0}%) rotate(-25deg)}
 50% { transform:translateY(${20}%) rotate(-20deg)}
 100% { transform:translateY(${0}%) rotate(-25deg)}
`;

const Clown1 = styled.img`
  z-index: 0;
  width: 17vw;
  height: 17vw;
  position: absolute;
  top: 42%;
  left: 6%;
  border-radius: 40px;
  animation-name: ${ClownFloatAnimation};
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-delay: 105ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
`;

const Clown2FloatAnimation = keyframes`
 0% { transform:translateY(${0}%) rotate(25deg) rotateY(180deg)}
 50% { transform:translateY(${20}%) rotate(20deg) rotateY(180deg)}
 100% { transform:translateY(${0}%) rotate(25deg) rotateY(180deg)}
`;

const Clown2 = styled.img`
  z-index: 0;
  width: 17vw;
  height: 17vw;
  position: absolute;
  top: 42%;
  right: 6%;
  transform: rotateZ(25deg) rotateY(180deg);
  border-radius: 95px;
  animation-name: ${Clown2FloatAnimation};
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-delay: ${(props) => props.offset * 0}ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
`;

const ComingSoonMessage = styled(Message)`
  font-size: 3vw;
  color: ${(props) => props.theme.color.white};
  animation-name: ${breathing};
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: ${(props) => props.offset * 100}ms;
  line-height: 0.3;
  opacity: ${(props) => [1, 0.8, 0.6, 0.4, 0.2][props.offset]};

  @media (max-width: 750px) {
    font-size: 6vw;
    opacity: 1;
  }
`;

const CustomMessage = styled(Message)`
  color: ${(props) => props.theme.color.white};
  font-size: 8vw;
  line-height: 0.1;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  animation-name: ${floatAnimation};
  animation-duration: 7s;
  animation-timing-function: ease-in-out;
  animation-delay: ${(props) => props.offset * 1000}ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
`;

export default IntroScreen;
