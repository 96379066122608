import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Typewriter, useTypewriter, Cursor } from 'react-simple-typewriter';
import { Box } from 'retro-ui';

const MainContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-image: ${(props) =>
    `linear-gradient(
       ${props.theme.color.yellow}, rgba(247, 208, 56, .97), ${props.theme.color.yellow}
     )`};

  @media (max-width: 750px) {
    flex-direction: column;
  }
`;

const InfoScreen = () => {
  const { text } = useTypewriter({
    deleteSpeed: 5,
    words: [
      `WHO ARE THE ODD CLOWNS?`,
      `Odd Clowns are a collection of 7,777 randomly generated NFTs on the Ethereum blockchain!`,
      `Each Clown also has a unique combination of randomised traits.`,
      `Mint Date Q2 2023`,
      `HOW MUCH WILL CLOWNS COST TO MINT?
      0.177 ETH each (max mint 3)`,
    ],
    loop: 0,
  });

  return (
    <MainContainer>
      <ImageContainer>
        <img
          style={{ width: '90%' }}
          src='./clowns-image.webp'
          alt='software'
        />
      </ImageContainer>
      <TextContainer>
        <Box style={{ margin: 20, zIndex: 10900 }}>
          {text}
          <Cursor />
        </Box>
        <Clown2 src='clowns/3.png' alt='clown2' />
      </TextContainer>
    </MainContainer>
  );
};

const Clown2FloatAnimation = keyframes`
 0% { transform:translateY(${0}%) rotate(10deg) rotateY(180deg)}
 50% { transform:translateY(${10}%) rotate(10deg) rotateY(180deg)}
 100% { transform:translateY(${0}%) rotate(10deg) rotateY(180deg)}
`;

const Clown2 = styled.img`
  z-index: 1;
  width: 60%;
  border-radius: 95px;
  animation-name: ${Clown2FloatAnimation};
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-delay: ${(props) => props.offset * 0}ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
`;

const ImageContainer = styled.div`
  z-index: 1;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export default InfoScreen;
