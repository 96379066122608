import React from 'react';
import { ExplorerView, ExplorerIcon } from 'packard-belle';
import { ProgramContext } from '../contexts';

const DesktopView = () => (
  <ProgramContext.Consumer>
    {(context) => (
      <ExplorerView>
        {context.desktop.map((option) => {
          return <ExplorerIcon key={option.title} {...option} />;
        })}
      </ExplorerView>
    )}
  </ProgramContext.Consumer>
);

export default DesktopView;
