import React from 'react';
import { WindowProgram } from 'packard-belle';

import Window from '../tools/Window';
import InfoScreen from '../../screens/InfoScreen';

function StoryView(props) {
  return (
    <Window
      {...props}
      Component={WindowProgram}
      title={'The secrets of the clowns'}
      minHeight={600}
      minWidth={300}
      maximizeOnOpen
    >
      <InfoScreen />
    </Window>
  );
}

export default StoryView;
