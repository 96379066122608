import React, { useContext, useEffect } from 'react';
import cx from 'classnames';
import { Theme } from 'packard-belle';
import { ThemeProvider } from 'styled-components';
import { ThemeWrapper } from 'retro-ui';
import { ToastContainer } from 'react-toastify';

import SettingsProvider from './contexts/settings';
import ProgramProvider from './contexts/programs';
import DesktopView from './screens/DesktopView';
import TaskBar from './components/TaskBar';
import WindowManager from './components/WindowManager';
import TaskManager from './components/TaskManager';
import Settings from './components/Settings';
import CRTOverlay from './components/tools/CRT';
import ShutDown from './components/ShutDown/ShutDown';
import Background from './components/tools/Background';
import { ProgramContext, SettingsContext } from './contexts';


import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import theme from './theme';

function Desktop() {
  const context = useContext(SettingsContext);
  const { desktop } = useContext(ProgramContext);

  useEffect(() => {
    console.log(desktop);
    setTimeout(() => desktop[0].onDoubleClick(), 200);
    desktop[2].onDoubleClick();

    if (window.innerWidth < 800) {
      context.toggleMobile(true);
    }
  }, []);

  return (
    <Theme
      style={{ height: context.isMobile ? '92vh' : '100vh' }}
      className={cx('desktop screen', {
        desktopX2: context.scale === 2,
        desktopX1_5: context.scale === 1.5,
        notMobile: !context.isMobile,
        fullScreen: context.fullScreen,
      })}
    >
      <Background />
      <DesktopView />
      <TaskBar />
      <WindowManager />
      <TaskManager />
      <Settings />
      <ShutDown />
      {context.crt && <CRTOverlay />}
    </Theme>
  );
}

const App = () => (
  <ThemeProvider theme={theme}>
    <ThemeWrapper>
      <SettingsProvider>
        <ProgramProvider>
          <Desktop />
          <ToastContainer />
        </ProgramProvider>
      </SettingsProvider>
    </ThemeWrapper>
  </ThemeProvider>
);

export default App;
