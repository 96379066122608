const theme = {
  color: {
    red: 'rgba(230, 38, 31, 1)',
    orange: 'rgba(235, 117, 50, 1)',
    yellow: 'rgba(247, 208, 56, 1)',
    green: 'rgba(163, 224, 72, 1)',
    bgreen: 'rgba(73, 218, 154, 1)',
    cblue: 'rgba(52, 187, 230, 1)',
    blue: 'rgba(67, 85, 219, 1)',
    purple: 'rgba(210, 59, 231, 1)',
    white: '#fff',
  },
};

export default theme;
